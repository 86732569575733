.rbc-timeslot-group, .rbc-time-slot {
  height: 1.75rem;
  flex: auto;
  min-height: 0px;
}

.rbc-time-slot:hover {
  background-color: #cbe5fd !important;
}

.rbc-events-container {
  pointer-events: none;
}

/* Vertical border between days */
/* .rbc-time-content > * + * > *, */
/* Horizontal borders between time slots */
/* .rbc-timeslot-group, */
/* Horizontal borders between time gutter slots */
.rbc-time-gutter .rbc-timeslot-group,
/* Border aroung calendar */
.rbc-time-view,
/* Border underneath headers */
.rbc-time-content,
/* Right border of header */
.rbc-time-header.rbc-overflowing,
/* Right border of timeGutterHeader */
.rbc-time-header-content {
  border: none;
}

.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot {
  background-color: transparent !important;
  margin-top: 1px
}

/* Right header of the whole calendar */
.rbc-time-content {
  border-right: 1px solid #ddd;
}

/* Horizontal border between headers */
.rbc-header + .rbc-header {
  border-left: none;
}

/* Hide allday row */
.rbc-allday-cell {
  display: none;
}

.rbc-calendar {
  height: calc(100vh - 13rem);
}

.rbc-current-time-indicator {
  background-color: red;
}

.rbc-event-label, .rbc-event-content {
  display: inline-block;
}

.rbc-day-slot .rbc-event-content {
  height: 1rem;
  position: absolute;
  top: 0;
}
